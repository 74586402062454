<template>
    <div class="modal-container">
         <div class="image-container d-none d-md-block">
             <img  width="1365" height="1400" src="~/assets/home/bf_terms_and_conditions.webp" alt="terms and conditions">
         </div>
         <div class="text-container">
             <div class="mb-3 d-flex justify-content-between align-items-start gap-2">
                 <!-- <h3 class="m-0 flex-1 w-100">BLACK FRIDAY SALE</h3> -->
                 <h3 class="m-0 flex-1 w-100">CHRISTMAS SALE</h3>
             </div>
             
             <!-- <p class="grey-text">Make the most of our Black Friday Sale- The best one yet! Explore amazing savings on select jewelry settings, pre-designed rings, and fine jewelry:</p> -->
             <p class="grey-text">Make the most of our Christmas Sale- The best one yet! Explore amazing savings on Engagement Rings and Fine Jewelry. Offer not applicable on Loose Diamonds and High Jewelry Collections (Soirée and Hues).  
            </p>
            <p class="grey-text text-bold">Enjoy FLAT 40% OFF Sitewide</p>
            
             <p class="grey-text">These limited-time offers end on <span class="highlight">December 25th, 2024, at 11:59 PM EST.</span> Friendly Diamonds reserves the right to modify these promotions at any time.</p>
         </div>
    </div>
 </template>
 
 <script setup>

 </script>
 
 <style scoped>
   
     .largesvg {
         transform: scale(1.3);
         cursor: pointer;
         margin-bottom: 2px;
     }
     .modal-container{
         display: flex;
         border-radius: 8px;
         overflow: hidden;
         justify-content: center;
     }
     .image-container{
         /* max-width: 45%; */
         /* width: 40%; */
         /* border: 1px solid red;  */
     }
 
     .text-container{
         padding: 25px;
     }
 
     h3{
         text-align: left;
         font-size: 18px;
         font-weight: bold;
         line-height: 24px;
         letter-spacing: 3px;
         text-transform: uppercase;
         color: #212529;
     }
 
     a{
         color: var(--green);
         display: block;
     }
 
 
     li,p{
         color: #353535;
         font-size: 16px;
         line-height: 22px;
     }
     
     h3, li{
         margin-bottom: 1rem;
     }
 
     span{
         font-weight: bold;
         color: #353535;
     }
     
     /* ul li::marker {
         color: #212529;
     } */

     .grey-text{
        color: #606060;
     }

     .text-bold{
        color: #353535;
        line-height: 24px;
        font-weight: bold;  
     }
 
     @media(max-width:767px){
        .text-container{
            padding: 0;
        }
         h3{
             font-size: 16px;
         }
 
         li,p{
             font-size: 14px;
         }
 
         h3, li{
             margin-bottom: 16px;
         }
     }
 
     
 </style>
